import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "components/layout/layout"
import SEO from "components/seo"
import { Container } from 'components/layout/container'
import styles from './blog-post.module.scss'
import './prism.scss';
import GatsbyImage from 'gatsby-image'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next, otherLanguagePosts } = this.props.pageContext

    const englishPost = otherLanguagePosts.find(e => e.frontmatter.language === 'en');
    const thaiPost = otherLanguagePosts.find(e => e.frontmatter.language === 'th');

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <div className={styles.articlePage}>
          <Container>
            <article className={styles.article}>
              <header className={styles.articleHeader}>
                <h1 className={styles.articleTitle}>{post.frontmatter.title}</h1>
                <div className={styles.articleFeaturedImage}>
                  <GatsbyImage fluid={post.frontmatter.featuredImage.childImageSharp.fluid}></GatsbyImage>
                </div>
                {post.frontmatter.subtitle && (
                  <h2 className={styles.articleSubtitle}>{post.frontmatter.subtitle}</h2>
                )}
                {
                  englishPost && (
                    <div className={styles.articleLanguageLink}>
                      <Link to={englishPost.fields.slug} className="link">Read this post in English</Link>
                    </div>
                  )
                }
                {
                  thaiPost && (
                    <div className={styles.articleLanguageLink}>
                      <Link to={thaiPost.fields.slug} className="link">อ่านบทความนี้ในภาษาไทย</Link>
                    </div>
                  )
                }
                <div className={styles.articleAuthor}>
                  <div className={styles.articleMetaTitle}>
                    {post.frontmatter.language === 'en' ? 'Written By' : ''}
                    {post.frontmatter.language === 'th' ? 'เขียนโดย' : ''}
                  </div>
                  <div>{post.frontmatter.author}</div>
                </div>
                {
                  post.frontmatter.translator && (
                    <div className={styles.articleAuthor}>
                      <div className={styles.articleMetaTitle}>
                        {post.frontmatter.language === 'en' ? 'Translated By' : ''}
                        {post.frontmatter.language === 'th' ? 'แปลโดย' : ''}
                      </div>
                      <div>{post.frontmatter.translator}</div>
                    </div>
                  )
                }
                <div className={styles.articleDate}>{post.frontmatter.date} · {post.fields.readingTime.text}</div>

                {post.frontmatter.tags && (
                  <div className={styles.articleTags}>
                    {post.frontmatter.tags.map(tag => (
                      <div className={styles.articleTag} key="tag">{tag}</div>
                    ))}
                  </div>
                )}
              </header>
              <section
                dangerouslySetInnerHTML={{ __html: post.html }}
                className={`${styles.articleBody} ${styles.markdownBody}`}
              />
            </article>

            <nav className={styles.articleNav}>
              {previous && (
                <Link to={previous.fields.slug} rel="prev" className={`${styles.articleNavLink} link`}>
                  ← {previous.frontmatter.title}
                </Link>
              )}
              {next && (
                <Link to={next.fields.slug} rel="next" className={`${styles.articleNavLink} link`}>
                  {next.frontmatter.title} →
                </Link>
              )}
            </nav>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM DD, YYYY")
        description
        author
        translator
        tags
        language
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 750) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`
